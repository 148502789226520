import * as React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import {Helmet} from 'react-helmet';
import {graphql, Link, useStaticQuery} from 'gatsby';

import parse from 'html-react-parser';

import '../styles.scss';
import {Navigation} from '../parts/navigation';
import {Wave} from '../parts/wave';
import {Footer} from '../parts/footer';


const IndexPage = (props) => {

  const query = useStaticQuery(graphql`
      query {
          markdownRemark(frontmatter: {section: {eq: "about-me"}}) {
              id
              frontmatter {
                  section
                  path
                  title
              }
              html
          }
      }

  `);

  console.log(query);
  const sections = query?.markdownRemark?.html?.split('[[--SECTION_BREAK--]]');
  const firstSection = sections.shift();
  const pageContents = query?.markdownRemark?.html?.split('[[--SECTION_BREAK--]]');

  return (
    <main className="page-wrapper">
      <Helmet>
        <meta name="description" content="Kaloczi David's personal site."/>
        <title>David Kaloczi</title>
      </Helmet>
      <div className="page-section">
        <div className="page-main">

          <Navigation/>
          <div className="header">
            <div style={{paddingLeft: '1.5rem'}}>
              <h1>
                👋🏼 Hi, <br/> I am David
              </h1>
              <p className="italic">
                I like photography and I hate the outdated and ugly websites, and I do still hate them today.
              </p>
            </div>
            <StaticImage width={240} height={240} src="./media/me.jpg" alt="Me at Beachy Head." className="photo"/>
          </div>
          {parse(firstSection)}
        </div>
          <Wave waveNumber={0} />
      </div>

      {
        pageContents.filter((e, i)=> i>=1).map((section, index) => {
          return <div className="page-section" key={'index-'+index}>
            <div className="page-main">

              {parse(section)}
            </div>

            <Wave waveNumber={index+1}/>
          </div>


        })
      }


      <Footer/>

    </main>
  );
};

export default IndexPage;

